import React from 'react'
import { connect } from 'formik'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const Container = styled.div({
  flex: ({ flex }) => flex,
  border: ({ className }) =>
    !['checkbox-input', 'radio-input'].includes(className) && '1px solid',
  overflow: 'hidden',
  transition: 'border-color 225ms',
  borderColor: ({ theme }) => theme.colors.lightGray.color,
  '&:hover': {
    borderColor: ({ theme }) => theme.colors.darkGray.color,
  },
  '& label': {
    zIndex: 1,
  },
  '&& *': {
    fontFamily: 'dinPro, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
  },

  //
  // all form field basic styles
  '& .MuiFormControl-root': {
    '& .MuiFormLabel-root': {
      pointerEvents: 'none',
    },

    '& .MuiFormLabel-root:not(.MuiFormLabel-filled)': {
      position: 'absolute',
      left: '16px',
      top: '16px',
      transform: 'translate(0,0)',
    },

    '& .MuiInputBase-root': {
      marginTop: 0,
      padding: '12px 16px',

      // select styles
      '& .MuiSelect-root': {
        padding: '18px 24px 18px 16px',
        margin: '-12px -16px',
      },
      '&.Mui-focused>div:focus': {
        background: 'inherit',
      },
    },
  },

  // form fields that aren't textarea
  '& .MuiFormControl-root:not(.disable-center-label)': {
    '& .MuiFormLabel-root:not(.MuiFormLabel-filled)': {
      position: 'absolute',
      left: '16px',
      top: '50%',
      transform: 'translate(0,-50%)',
    },
  },

  // form fields that shouldn't vertically center the label
  '& .MuiFormControl-root.disable-center-label': {
    '& .MuiInputBase-root': {
      paddingTop: '20px',
    },
  },
})

const StyledTextField = styled(TextField)`
  background-color: white;
  && .Mui-focused {
    color: ${({ theme }) => theme.colors.base.darkGray};
    input::placeholder {
      color: ${({ theme }) => theme.colors.base.darkGray};
    }
  }

  & .MuiInputLabel-root {
    pointer-events: none;
  }
`

const RadioFormControlLabel = styled(FormControlLabel)`
  min-width: calc(50% - 2px);
`

const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: row;
  flex: 1;
`

const StyledRadio = styled(Radio)``

function StoryblokInput(props) {
  const { data, formik, noLabel, ...rest } = props

  const field = {
    ...data.field?.option,
    options: data.field?.option?.data?.options
      ?.map(opt => ({
        ...opt,
        label: data.field.optionLabels[`label_${opt.id}`],
      }))
      .filter(opt => opt.label),
  }

  if (field.type === 'checkbox') {
    return (
      <Container flex={data.flex} className="checkbox-input">
        <FormControlLabel
          control={
            <Checkbox
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values[field.name]}
              name={field.name}
              id={field.name}
              required={data.required}
            />
          }
          label={data.label}
        />
      </Container>
    )
  }
  if (field.type === 'radio') {
    return (
      <Container flex={field.flex} className="radio-input">
        <StyledRadioGroup
          name={field.name}
          id={field.name}
          onChange={formik.handleChange}
          value={formik.values[field.name]}
          required={data.required}
        >
          {field.options?.map(({ label, name, id }) => (
            <RadioFormControlLabel
              key={id}
              value={name}
              control={<StyledRadio />}
              label={label}
            />
          ))}
        </StyledRadioGroup>
      </Container>
    )
  }
  /* if (data.type === 'select') {
    return (
      <SelectContainer {...props}>
        {!noLabel && (
          <StyledInputLabel htmlFor={data.name}>{data.label}</StyledInputLabel>
        )}
        <StyledSelect
          label={data.label}
          name={data.name}
          input={<StyledInput id={data.name} />}
          displayEmpty
          value={formik.values[data.name]}
          onChange={formik.handleChange}
        >
          {data.selectOptions?.map(({ label, value, id }) => (
            <MenuItem key={id} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
      </SelectContainer>
    )
  } */
  if (field.type === 'number' || field.type === 'email') rest.type = field.type
  return (
    <Container flex={data.flex}>
      <StyledTextField
        InputLabelProps={{ shrink: !!formik.values[field.name] }}
        required={data.required}
        select={field.type === 'select'}
        fullWidth
        multiline={field.type === 'textarea'}
        rows={5}
        id={field.name}
        name={field.name}
        label={!noLabel && data.label}
        placeholder={data.placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[field.name]}
        className={field.type === 'textarea' ? 'disable-center-label' : ''}
        InputProps={{
          disableUnderline: true,
          style: {
            backgroundColor: 'white',
          },
        }}
        {...(field.type === 'select' && {
          SelectProps: {
            MenuProps: {
              PopoverClasses: {
                paper: 'flat-field',
              },
            },
            inputProps: {
              required: !!data.required,
              type: 'text',
              style: {
                backgroundColor: 'transparent',
                color: 'transparent',
                border: 'none',
                position: 'absolute',
                pointerEvents: 'none',
              },
            },
          },
        })}
        {...rest}
      >
        {field.type === 'select' &&
          field.options?.map(({ name, id, label }) => (
            <MenuItem key={id} value={name}>
              {label}
            </MenuItem>
          ))}
      </StyledTextField>
    </Container>
  )
}

export default connect(StoryblokInput)
